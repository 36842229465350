import {ConsentHandler} from "~/@types/cookieConsentTypes"

const purposeToCategory = {
  core: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004', // ABTest, abTestActiveExperiments, braze content-cards
  social: 'C0005',
  dd: 'dd', // Datadog, Partners List
  ga: 'ga', // GTM
  '1clss': '1clss', // Last loggedIn user
  '3paie': '3paie', // Personalized ads and content
  '6prod': '6prod', // Product Development Category
  '7perf': '7perf', // Performance, User Data,
  'op': 'op',  // Optimizely, Partners List, AB Tests
  'bc': 'bc',  // Braze Core, Partners List, Braze Init
  'bz': 'bz', // Braze, Partners List, Content Cards
  'am': 'am' // AboutYou Personalization, Partners List
}

const didConsent = (
  purposes: string[],
  consents: string
) => {
  return purposes.find(purpose => {
    return consents.includes(purpose)
  })
}

if (typeof window !== 'undefined') {
  if(typeof window.consentHandlers === 'undefined') {
    window.consentHandlers = new Map();
  }

  window.OptanonWrapper = () => {
    window.consentHandlers?.forEach(consentHandler => consentHandler.invoke())
  }
}

export default function cookieConsent(
  alias: string,
  purposes: Array<keyof typeof purposeToCategory>,
  _fallbackConsentHandlersContainer: Map<string, ConsentHandler>
): ConsentHandler {
  const consentHandlers = typeof window !== 'undefined' && typeof window.consentHandlers !== 'undefined' ? window.consentHandlers : _fallbackConsentHandlersContainer;

  if (!consentHandlers.has(alias)) {
    const scopes: string[] = purposes.map(purpose => purposeToCategory[purpose] || purpose
    )
    const optInMethods: Map<string, Function> = new Map()
    const optOutMethods: Map<string, Function> = new Map()

    const invoke = (consented: string) => {
      if (didConsent(scopes, consented)) {
        optInMethods?.forEach(method => method())
        return true
      }
      else {
        optOutMethods?.forEach(method => method())
        return false
      }
    }

    const consentRegistration: ConsentHandler = {
      optIn(method, options) {
        optInMethods.set(options?.alias || method.name, method)
        return consentRegistration
      },
      optOut(method, options) {
        optOutMethods.set(options?.alias || method.name, method)
        return consentRegistration
      },
      invoke() {
        if (typeof window !== 'undefined') {
          return invoke(window.OnetrustActiveGroups || '')
        }

        return false
      }
    }

    consentHandlers.set(alias, consentRegistration)
  }

  return consentHandlers.get(alias) as ConsentHandler
}
