import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _98914e5e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4c944496 = () => interopDefault(import('../pages/basket.vue' /* webpackChunkName: "pages/basket" */))
const _699ad280 = () => interopDefault(import('../pages/bulk-upload/index.vue' /* webpackChunkName: "pages/bulk-upload/index" */))
const _31245238 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _586ee242 = () => interopDefault(import('../pages/deal-planning/index.vue' /* webpackChunkName: "pages/deal-planning/index" */))
const _1b29758e = () => interopDefault(import('../pages/page-not-found.vue' /* webpackChunkName: "pages/page-not-found" */))
const _2afb41ad = () => interopDefault(import('../pages/server-error.vue' /* webpackChunkName: "pages/server-error" */))
const _4c6cc958 = () => interopDefault(import('../pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _02c3eabd = () => interopDefault(import('../pages/a/password-reset/index.vue' /* webpackChunkName: "pages/a/password-reset/index" */))
const _6a3d58c6 = () => interopDefault(import('../pages/account/bank-data/index.vue' /* webpackChunkName: "pages/account/bank-data/index" */))
const _5bd7b210 = () => interopDefault(import('../pages/account/order/index.vue' /* webpackChunkName: "pages/account/order/index" */))
const _d4ad2d22 = () => interopDefault(import('../pages/account/password/index.vue' /* webpackChunkName: "pages/account/password/index" */))
const _333a831f = () => interopDefault(import('../pages/account/user/index.vue' /* webpackChunkName: "pages/account/user/index" */))
const _fe347da8 = () => interopDefault(import('../pages/checkout/success/index.vue' /* webpackChunkName: "pages/checkout/success/index" */))
const _6ab75530 = () => interopDefault(import('../pages/account/password/validate/confirmPassword.ts' /* webpackChunkName: "pages/account/password/validate/confirmPassword" */))
const _00c97e52 = () => interopDefault(import('../pages/account/password/validate/currentPassword.ts' /* webpackChunkName: "pages/account/password/validate/currentPassword" */))
const _87513560 = () => interopDefault(import('../pages/account/password/validate/newPassword.ts' /* webpackChunkName: "pages/account/password/validate/newPassword" */))
const _341c119a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9a6a0f10 = () => interopDefault(import('../pages/account/order/_id.vue' /* webpackChunkName: "pages/account/order/_id" */))
const _abfe10a6 = () => interopDefault(import('../pages/account/returns/_id.vue' /* webpackChunkName: "pages/account/returns/_id" */))
const _5b4f290d = () => interopDefault(import('../pages/all-products/_category.vue' /* webpackChunkName: "pages/all-products/_category" */))
const _84a3690e = () => interopDefault(import('../pages/deals/_category/index.vue' /* webpackChunkName: "pages/deals/_category/index" */))
const _aadd4de2 = () => interopDefault(import('../pages/s/_slug.vue' /* webpackChunkName: "pages/s/_slug" */))
const _691ad821 = () => interopDefault(import('../pages/deals/_category/_id.vue' /* webpackChunkName: "pages/deals/_category/_id" */))
const _0b5c76c8 = () => interopDefault(import('../pages/p/_brand/_slug/_id.vue' /* webpackChunkName: "pages/p/_brand/_slug/_id" */))
const _435d5293 = () => interopDefault(import('../pages/b/_.vue' /* webpackChunkName: "pages/b/_" */))
const _78438dd4 = () => interopDefault(import('../pages/c/_.vue' /* webpackChunkName: "pages/c/_" */))
const _1256a665 = () => interopDefault(import('../pages/_country/s/_slug.vue' /* webpackChunkName: "pages/_country/s/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _98914e5e,
    name: "account___at"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___bg"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___cz"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___de"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___el"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___hr"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___hu"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___lt"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___lv"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___pl"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___ro"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___si"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___sk"
  }, {
    path: "/account",
    component: _98914e5e,
    name: "account___en"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___at"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___bg"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___cz"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___de"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___el"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___hr"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___hu"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___lt"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___lv"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___pl"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___ro"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___si"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___sk"
  }, {
    path: "/basket",
    component: _4c944496,
    name: "basket___en"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___at"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___bg"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___cz"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___de"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___el"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___hr"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___hu"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___lt"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___lv"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___pl"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___ro"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___si"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___sk"
  }, {
    path: "/bulk-upload",
    component: _699ad280,
    name: "bulk-upload___en"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___at"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___bg"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___cz"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___de"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___el"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___hr"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___hu"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___lt"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___lv"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___pl"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___ro"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___si"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___sk"
  }, {
    path: "/checkout",
    component: _31245238,
    name: "checkout___en"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___at"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___bg"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___cz"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___de"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___el"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___hr"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___hu"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___lt"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___lv"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___pl"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___ro"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___si"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___sk"
  }, {
    path: "/deal-planning",
    component: _586ee242,
    name: "deal-planning___en"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___at"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___bg"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___cz"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___de"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___el"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___hr"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___hu"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___lt"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___lv"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___pl"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___ro"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___si"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___sk"
  }, {
    path: "/page-not-found",
    component: _1b29758e,
    name: "page-not-found___en"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___at"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___bg"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___cz"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___de"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___el"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___hr"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___hu"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___lt"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___lv"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___pl"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___ro"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___si"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___sk"
  }, {
    path: "/server-error",
    component: _2afb41ad,
    name: "server-error___en"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___at"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___bg"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___cz"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___de"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___el"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___hr"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___hu"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___lt"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___lv"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___pl"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___ro"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___si"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___sk"
  }, {
    path: "/signin",
    component: _4c6cc958,
    name: "signin___en"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___at"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___bg"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___cz"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___de"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___el"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___hr"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___hu"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___lt"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___lv"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___pl"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___ro"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___si"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___sk"
  }, {
    path: "/a/password-reset",
    component: _02c3eabd,
    name: "a-password-reset___en"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___at"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___bg"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___cz"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___de"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___el"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___hr"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___hu"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___lt"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___lv"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___pl"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___ro"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___si"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___sk"
  }, {
    path: "/account/bank-data",
    component: _6a3d58c6,
    name: "account-bank-data___en"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___at"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___bg"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___cz"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___de"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___el"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___hr"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___hu"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___lt"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___lv"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___pl"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___ro"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___si"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___sk"
  }, {
    path: "/account/order",
    component: _5bd7b210,
    name: "account-order___en"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___at"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___bg"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___cz"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___de"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___el"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___hr"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___hu"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___lt"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___lv"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___pl"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___ro"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___si"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___sk"
  }, {
    path: "/account/password",
    component: _d4ad2d22,
    name: "account-password___en"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___at"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___bg"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___cz"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___de"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___el"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___hr"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___hu"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___lt"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___lv"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___pl"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___ro"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___si"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___sk"
  }, {
    path: "/account/user",
    component: _333a831f,
    name: "account-user___en"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___at"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___bg"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___cz"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___de"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___el"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___hr"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___hu"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___lt"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___lv"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___pl"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___ro"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___si"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___sk"
  }, {
    path: "/checkout/success",
    component: _fe347da8,
    name: "checkout-success___en"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___at"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___bg"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___cz"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___de"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___el"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___hr"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___hu"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___lt"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___lv"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___pl"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___ro"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___si"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___sk"
  }, {
    path: "/account/password/validate/confirmPassword",
    component: _6ab75530,
    name: "account-password-validate-confirmPassword___en"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___at"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___bg"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___cz"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___de"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___el"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___hr"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___hu"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___lt"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___lv"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___pl"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___ro"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___si"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___sk"
  }, {
    path: "/account/password/validate/currentPassword",
    component: _00c97e52,
    name: "account-password-validate-currentPassword___en"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___at"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___bg"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___cz"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___de"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___el"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___hr"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___hu"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___lt"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___lv"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___pl"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___ro"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___si"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___sk"
  }, {
    path: "/account/password/validate/newPassword",
    component: _87513560,
    name: "account-password-validate-newPassword___en"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___at"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___bg"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___cz"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___de"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___el"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___hr"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___hu"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___lt"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___lv"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___pl"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___ro"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___si"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___sk"
  }, {
    path: "/",
    component: _341c119a,
    name: "index___en"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___at"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___bg"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___cz"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___de"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___el"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___hr"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___hu"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___lt"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___lv"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___pl"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___ro"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___si"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___sk"
  }, {
    path: "/account/order/:id",
    component: _9a6a0f10,
    name: "account-order-id___en"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___at"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___bg"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___cz"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___de"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___el"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___hr"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___hu"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___lt"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___lv"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___pl"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___ro"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___si"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___sk"
  }, {
    path: "/account/returns/:id?",
    component: _abfe10a6,
    name: "account-returns-id___en"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___at"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___bg"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___cz"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___de"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___el"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___hr"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___hu"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___lt"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___lv"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___pl"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___ro"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___si"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___sk"
  }, {
    path: "/all-products/:category?",
    component: _5b4f290d,
    name: "all-products-category___en"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___at"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___bg"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___cz"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___de"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___el"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___hr"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___hu"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___lt"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___lv"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___pl"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___ro"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___si"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___sk"
  }, {
    path: "/deals/:category",
    component: _84a3690e,
    name: "deals-category___en"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___at"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___bg"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___cz"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___de"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___el"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___hr"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___hu"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___lt"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___lv"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___pl"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___ro"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___si"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___sk"
  }, {
    path: "/s/:slug?",
    component: _aadd4de2,
    name: "s-slug___en"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___at"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___bg"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___cz"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___de"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___el"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___hr"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___hu"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___lt"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___lv"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___pl"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___ro"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___si"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___sk"
  }, {
    path: "/deals/:category?/:id",
    component: _691ad821,
    name: "deals-category-id___en"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___at"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___bg"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___cz"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___de"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___el"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___hr"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___hu"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___lt"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___lv"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___pl"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___ro"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___si"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___sk"
  }, {
    path: "/p/:brand?/:slug?/:id?",
    component: _0b5c76c8,
    name: "p-brand-slug-id___en"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___en"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___sk"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___si"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___ro"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___pl"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___lv"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___lt"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___hu"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___hr"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___el"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___de"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___cz"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___bg"
  }, {
    path: "/b/*",
    component: _435d5293,
    name: "b-all___at"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___en"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___sk"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___si"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___ro"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___pl"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___lv"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___lt"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___hu"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___hr"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___el"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___de"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___cz"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___bg"
  }, {
    path: "/c/*",
    component: _78438dd4,
    name: "c-all___at"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___at"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___bg"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___cz"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___de"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___el"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___hr"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___hu"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___lt"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___lv"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___pl"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___ro"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___si"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___sk"
  }, {
    path: "/:country/s/:slug?",
    component: _1256a665,
    name: "country-s-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
