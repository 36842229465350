export * from './type'
export {domains} from './domains'
export {type APP_KEYS, appKeys} from './app-keys'
export { type AVAILABLE_SHOP_IDS} from './availableShopIds'

import countries from './config'

export const SHOP_IDS = countries.map(c => c.shopId)

export default countries
